import React from 'react'
import './tableGrid.css'

const TableGrid = ({ tableData }) => {
  if (!tableData || !tableData.rows || tableData.rows.length === 0) {
    return <p>Нет данных для отображения</p>
  }

  const columnCount = tableData.rows[0].cells.length

  const headers = tableData.rows[0].cells

  return (
    <div
      className='table-grid'
      style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}
    >
      {tableData.rows.map((row, rowIndex) =>
        row.cells.map((cell, cellIndex) => (
          <div
            key={row._key + cellIndex}
            className={`table-cell ${rowIndex === 0 ? 'headers' : ''}`}
            data-label={headers[cellIndex]}
          ><div dangerouslySetInnerHTML={{ __html: cell }} />
          </div>
        ))
      )}
    </div>
  )
}

export default TableGrid
