import React from 'react'

import styles from './figure.module.css'
import { Image } from '../Image'

function Figure (props) {
  return (
    <figure
      className={`${styles.root} ${props.class ? props.class : ''}`}
      onClick={props !== null ? () => props.onClick(props) : undefined}
      style={{ cursor: props.onClick ? 'zoom-in' : 'default' }}
    >
      {props.asset && (
        <Image
          imgObj={props}
        />
      )}
      <figcaption className={styles.caption}>{props.caption}</figcaption>
    </figure>
  )
}

export default Figure
