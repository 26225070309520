import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import Container from '../components/container'
import BlockContent from '../components/block-content/v2'

import postStyles from '../styles/components/written-post.module.css'
import Slideshow from '../components/block-content/slideshow'
import styles from '../components/globals-v2.module.css'
import TableGrid from '../components/block-content/tableGrid'

export const query = graphql`
    query PrivacyPageQuery {
        page: sanityPage(title: {eq: "Privacy Policy"}) {
            title
            _rawBody
            metaTitle
            metaDescription
            metaKeywords
            metaImage {
                asset {
                    _id
                    url
                }
            }
        }
    }
`

const serializers = {
  types: {
    block: props => {
      switch (props.node.style) {
        case 'h1':
          return <h1>{props.children}</h1>

        case 'h2':
          return <h2 id={props.node._key} className={postStyles.writtenPostContentH2}>{props.children}</h2>

        case 'h3':
          return <h3 className={postStyles.writtenPostContentH3}>{props.children}</h3>

        case 'h4':
          return <h4>{props.children}</h4>

        case 'normal':
          return <p className={postStyles.writtenPostContentText}>{props.children}</p>
        default:
          if (props.children.length === 1 && typeof props.children[0] === 'string' && props.children[0].trim() === '') {
            return ''
          } else {
            return <p className={postStyles.writtenPostContentText}>{props.children}</p>
          }
      }
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    },
    embedCode (props) {
      if (props.node.code) {
        return <div dangerouslySetInnerHTML={{ __html: props.node.code }} />
      }
      return <></>
    },
    code: props => (
      <pre className={postStyles.codeBlock}>
        <code>{props.node.code}</code>
      </pre>
    ),
    table (props) {
      if (!props.node) return <></>
      return (<TableGrid tableData={props.node} />)
    }
  },
  list: ({ children, type }) => {
    if (type === 'bullet') {
      return <ul className={postStyles.writtenPostTextList}>{children}</ul>
    }
    if (type === 'number') {
      return <ol className={postStyles.writtenPostTextList}>{children}</ol>
    }
    return null
  },
  listItem: ({ children }) => <li>{children}</li>
}

const PrivacyPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Privacy Policy" page data. Open the studio at http://localhost:3333 and add "Privacy Policy" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords} imageFB={data?.page?.metaImage?.asset.url || ''} imageTW={data?.page?.metaImage?.asset.url || ''} />

      <Hero>
        {page.title}
      </Hero>

      <Container class={`${styles.container} ${postStyles.writtenPostContent}`}>
        <BlockContent blocks={page._rawBody} serializers={serializers} />
      </Container>
    </Layout>
  )
}

export default PrivacyPage
